<form [formGroup]="agencyForm" *ngIf="(loading$ | async) === false">
  <div class="container container--small u-marginTxxl u-flex">
    <div class="u-width--full u-flex u-flex-align--center">
      <h2>Agency</h2>
      <a
        mat-icon-button
        class="u-paddingTxxs"
        [href]="agencyLink"
        target="_blank"
      >
        <app-icon name="link-simple-horizontal" class="o-btn__icon"></app-icon>
      </a>
    </div>
  </div>

  <div class="container container--small">
    <div class="u-marginBm u-flex">
      <div class="u-marginRxs">
        <mat-label class="o-form-label">Name</mat-label>
        <mat-form-field>
          <input
            type="text"
            matInput
            formControlName="name"
            placeholder="Name"
            autocomplete="off"
            [skElementId]="e2e.AgencyInputFieldName"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-label class="o-form-label">Hubspot Company Id *</mat-label>
        <mat-form-field>
          <input
            type="text"
            matInput
            formControlName="hubspotCompanyId"
            placeholder="ID"
            autocomplete="off"
            [skElementId]="e2e.AgencyInputFieldHubspotCompanyId"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="u-marginBm u-flex">
      <div>
        <mat-checkbox
          formControlName="active"
          [skElementId]="e2e.AgencyCheckboxStatus"
          >Active</mat-checkbox
        >
        <div
          class="u-color--medium"
          *ngIf="
            (agency$ | async)?.active === false &&
            (agency$ | async)?.inactiveSince
          "
        >
          (Inactive Since: {{ (agency$ | async)?.inactiveSince | date }})
        </div>
      </div>
    </div>

    <ng-container [ngSwitch]="mode$ | async">
      <div class="u-marginBs u-flex">
        <div *ngIf="mode$ | async as mode" class="u-marginRxs">
          <mat-label class="o-form-label">Customer Type</mat-label>
          <mat-form-field [skElementId]="e2e.AgencyDropdownCustomerType">
            <mat-select
              formControlName="customerType"
              [skElementId]="e2e.AgencyDropdownCustomerTypeList"
            >
              <mat-option
                *ngFor="let customer of customerTypeOptions; let i = index"
                value="{{ customer }}"
                [skElementId]="e2e.AgencyDropdownCustomerTypeItem"
                [skElementIdIndex]="i"
              >
                {{ customer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div formGroupName="featureSettings" class="u-marginRxs">
          <mat-label class="o-form-label"> Users Limit </mat-label>
          <mat-form-field
            formGroupName="maxUsersAmount"
            [skElementId]="e2e.AgencyInputFieldUsersLimit"
          >
            <input type="number" matInput formControlName="value" min="0" />
          </mat-form-field>
        </div>

        <div
          formGroupName="featureSettings"
          *ngIf="isFreemiumAgency"
          class="u-marginRxs"
        >
          <mat-label class="o-form-label"
            >Custom Font Slots Remaining</mat-label
          >
          <mat-form-field class="u-marginRxs" formGroupName="maxCustomFont">
            <input type="number" matInput formControlName="value" min="0" />
          </mat-form-field>
        </div>

        <div formGroupName="featureSettings">
          <mat-label class="o-form-label">Customer Admin</mat-label>
          <div formGroupName="customerAdmin">
            <mat-checkbox
              formControlName="value"
              [skElementId]="e2e.AgencyCheckboxCustomerAdmin"
            >
              Enable
            </mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="container container--small u-paddingBxl"
    *ngIf="mode$ | async as mode"
  >
    <mat-tab-group animationDuration="0ms">
      <!-- CLIENTS TAB -->
      <mat-tab *ngIf="mode === modes.Edit">
        <ng-template mat-tab-label>
          <div
            class="u-flex u-flex-align--center"
            [skElementId]="e2e.AgencyMenuItemClients"
          >
            Clients ( {{ (clients$ | async)?.length }} )
            <span class="unsaved-change">
              <pre>{{ hasUnsavedClientChanges ? '!' : '' }}</pre>
            </span>
          </div>
        </ng-template>

        <div *ngIf="clients$ | async; let clients">
          <div class="container container--small u-flex u-width--full">
            <h2>Agency Clients</h2>
          </div>

          <div class="container container--small u-paddingBxl">
            <mat-table
              [dataSource]="clientDataSource"
              class="client-table"
              [skElementId]="e2e.AgencyTableAgencyClients"
            >
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  [skElementId]="e2e.AgencyClientsTableRowName"
                >
                  {{ element.client.name }}
                  <span
                    class="client-unsaved-change"
                    *ngIf="element.new || element.updated || element.deleted"
                  >
                    <pre> !</pre>
                  </span>
                </mat-cell>
              </ng-container>
              <form [formGroup]="clientDataForm" class="u-marginTm">
                <ng-container matColumnDef="hubspotId">
                  <mat-header-cell *matHeaderCellDef>
                    Hubspot Company Id *
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let element; let i = index"
                    [skElementId]="e2e.AgencyInputFieldHubspotCompanyForClient"
                  >
                    <div>
                      <mat-form-field class="u-marginRxs mat-form-field-small">
                        <input
                          type="text"
                          matInput
                          formControlName="{{
                            clientFormFields.hubspotCompanyId
                          }}{{ i }}"
                          placeholder="ID"
                          autocomplete="off"
                          (keyup)="
                            saveClient(
                              element,
                              clientFormFields.hubspotCompanyId + i,
                              i
                            )
                          "
                        />
                      </mat-form-field>
                    </div>
                  </mat-cell>
                </ng-container>
              </form>
              <ng-container matColumnDef="delete">
                <mat-header-cell *matHeaderCellDef class="delete-column">
                  Delete
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element; let i = index"
                  class="delete-column"
                >
                  <button
                    mat-icon-button
                    type="button"
                    (click)="deleteClient(element, i)"
                    [skElementId]="e2e.ClientButtonDelete"
                  >
                    <app-icon
                      name="trash"
                      weight="fill"
                      class="u-color--negative u-iconsize--m o-btn__icon"
                    ></app-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="link">
                <mat-header-cell
                  *matHeaderCellDef
                  class="delete-column u-paddingLxxs"
                >
                  Link
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let clientTableEntry"
                  class="delete-column"
                >
                  <a
                    mat-icon-button
                    [href]="generateClientLinkCreate(clientTableEntry)"
                    *ngIf="!clientTableEntry.new"
                    target="_blank"
                  >
                    <app-icon
                      name="link-simple-horizontal"
                      class="o-btn__icon u-iconsize--m"
                    ></app-icon>
                  </a>
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="clientTableColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: clientTableColumns"
                [hidden]="row.deleted"
                [skElementId]="e2e.AgencyClientsTableRow"
                [skElementIdIndex]="row.index"
              ></mat-row>
            </mat-table>
            <form [formGroup]="clientForm" class="u-marginTm">
              <mat-label class="o-form-label u-marginT"
                >Create a new client</mat-label
              >
              <div class="client-form">
                <mat-form-field class="u-marginRxs">
                  <input
                    type="text"
                    matInput
                    formControlName="name"
                    placeholder="Name"
                    autocomplete="off"
                    (blur)="clientInputBlur()"
                    [skElementId]="e2e.AgencyInputFieldNewClientName"
                  />
                </mat-form-field>
                <button
                  [disabled]="clientForm.invalid"
                  mat-flat-button
                  color="primary"
                  (click)="addClient()"
                  [skElementId]="e2e.AgencyButtonCreateClient"
                >
                  Create Client
                </button>
              </div>
            </form>
          </div>
        </div>
      </mat-tab>
      <!-- PREMIUM FEATURES TAB -->
      <mat-tab>
        <ng-template mat-tab-label>
          <div
            class="u-flex u-flex-align--center"
            [skElementId]="e2e.AgencyMenuItemPremiumFeatures"
          >
            Premium Features
            <span class="unsaved-change">
              <pre>{{ hasUnsavedPremiumChanges ? '!' : '' }}</pre>
            </span>
          </div>
        </ng-template>
        <div class="u-paddingVm">
          <app-premium-features-form
            [form]="agencyFeatureSettingsFormGroup"
          ></app-premium-features-form>
        </div>
      </mat-tab>
      <!-- FREE TRIAL SETTINGS TAB -->
      <mat-tab>
        <ng-template mat-tab-label>
          Free Trial Settings
          <span class="unsaved-change">
            <pre>{{ hasUnsavedFreeTrialChanges ? '!' : '' }}</pre>
          </span>
        </ng-template>
        <div class="u-paddingVm">
          <app-free-trial-form [form]="agencyForm"></app-free-trial-form></div
      ></mat-tab>
      <ng-container *ngIf="mode === modes.Edit">
        <!-- FONTS TAB -->
        <mat-tab>
          <ng-template mat-tab-label>
            <div
              class="u-flex u-flex-align--center"
              [skElementId]="e2e.AgencyMenuItemFonts"
            >
              Fonts ( {{ (fonts$ | async)?.length }} )
              <span class="unsaved-change">
                <pre>{{ hasUnsavedFontChanges ? '!' : '' }}</pre>
              </span>
            </div>
          </ng-template>
          <div class="u-paddingBm u-width--full">
            <app-font-calc-list
              [agencyId]="(agencyId$ | async)!"
              [fonts]="(fonts$ | async)!"
              [clients]="(clients$ | async)!"
              (hasUnsavedFontChanges)="fontChangesHandler($event)"
              [maxCustomFonts]="isFreemiumAgency ? maxCustomFonts : undefined"
              (updateMaxCustomFonts)="updateMaxCustomFonts($event)"
            ></app-font-calc-list></div
        ></mat-tab>
        <!-- USERS TAB -->
        <mat-tab *ngIf="(agencyId$ | async) && (clients$ | async)">
          <ng-template mat-tab-label>
            <div
              class="u-flex u-flex-align--center"
              [skElementId]="e2e.AgencyMenuItemUser"
            >
              Users ( {{ (usersDataSource.users$ | async)?.users?.length }} )
            </div>
          </ng-template>
          <div class="container container--small u-flex u-width--full">
            <h2>Agency/Clients' Users</h2>
          </div>
          <div class="container container--small u-flex u-width--full">
            <app-user-list
              class="u-width--full"
              [usersDataSource]="usersDataSource"
            ></app-user-list>
          </div>
        </mat-tab>
        <!-- SCRIPT CREATORS TAB -->
        <mat-tab
          label="Script Creators"
          *ngIf="(agencyId$ | async) && (canEditLanguageInstruction$ | async)"
        >
          <div formGroupName="settings">
            <div class="container container--small u-flex u-width--full">
              <h2>Script Creators</h2>
            </div>
            <div class="container container--small u-flex u-width--full">
              <div style="width: 100%">
                <mat-label class="o-form-label"
                  >Language Instruction (Do not use without approval from
                  Product Department)</mat-label
                >
                <mat-form-field class="u-marginRxs">
                  <textarea
                    matInput
                    formControlName="scriptCreatorLanguageInstruction"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
  <ng-container [ngSwitch]="mode$ | async">
    <ng-container *ngSwitchCase="modes.Add">
      <div class="container container--small u-marginTl u-paddingBxl">
        <button
          [disabled]="formLoading || agencyForm.invalid"
          mat-flat-button
          data-test-id="create-agency-button"
          color="primary"
          (click)="createAgency()"
          [skElementId]="e2e.AgencyButtonCreateAgency"
        >
          Create Agency
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="modes.Edit">
      <div class="container container--small warning-unsaved-changes">
        <span
          *ngIf="
            hasUnsavedClientChanges ||
            hasUnsavedFontChanges ||
            hasUnsavedFormChanges
          "
          >Unsaved Changes!</span
        >
      </div>
      <div class="container container--small u-paddingBxl">
        <button
          [disabled]="
            formLoading ||
            agencyForm.invalid ||
            clientDataForm.invalid ||
            hasExpiredTrial
          "
          mat-flat-button
          data-test-id="update-agency-button"
          color="primary"
          (click)="updateAgency()"
          [skElementId]="e2e.AgencyButtonUpdateAgency"
        >
          Update Agency
        </button>
        <button
          mat-flat-button
          [disabled]="formLoading"
          data-test-id="delete-agency-button"
          color="warn"
          class="u-marginLs"
          (click)="openDeleteDialog()"
          [skElementId]="e2e.AgencyButtonDeleteAgency"
        >
          Delete Agency
        </button>
        <button
          mat-stroked-button
          [disabled]="formLoading || !hasExpiredTrial"
          data-test-id="restart-agency-button"
          color="primary"
          class="u-marginLs"
          (click)="openRestartTrialDialog()"
          [skElementId]="e2e.AgencyButtonRestartTrial"
        >
          Reactivate Trial Agency
        </button>
      </div>
    </ng-container>
  </ng-container>
</form>
<app-error [observable$]="agency$"></app-error>
