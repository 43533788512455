import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { AgencyService } from '@core/services/agency.service';
import { NotificationService } from '@shared/notification/notification.service';
import { Modes } from '@models/modes';
import { Agency } from '@models/agency';
import {
  CustomerType as CustomerTypes,
  ElementId,
  ElementIdType,
  FEATURE,
} from '@storykit/constants';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  Observable,
  of,
} from 'rxjs';
import { environment } from '@environment';

import {
  filter,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormRecord,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AgencyNotification } from '@shared/notification/agency-messages';
import { ConfirmDialogComponent } from '@views/partials/confirm-dialog/confirm-dialog.component';
import { ClientService } from '@core/services/client.service';
import { Client } from '@models/client';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { ObservableCacheService } from '@core/services/observable-cache.service';
import { CwsService } from '@core/services/cws.service';
import {
  FreeTrialForm,
  FreeTrialFormComponent,
} from '@shared/free-trial-form/free-trial-form.component';
import { UserListDataSource } from '@views/user/user-list/user-list.dataSource';
import {
  AsyncPipe,
  DatePipe,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import {
  ElementIdDirective,
  FeatureService,
  IconComponent,
} from '@storykit/ui-components';
import {
  MatButton,
  MatIconAnchor,
  MatIconButton,
} from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatTab, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { PremiumFeaturesFormComponent } from '@shared/premium-features-form/premium-features-form.component';
import { FontCalcListComponent } from '@shared/font-calc-list/font-calc-list.component';
import { UserListComponent } from '@shared/user-list/user-list.component';
import { ErrorComponent } from '@shared/error/error.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE = {
  [CustomerTypes.LITE]: 1,
  [CustomerTypes.PRO]: 2,
  [CustomerTypes.ENTERPRISE]: 9999,
  /** @deprecated - freemium is deprecated */
  [CustomerTypes.FREEMIUM]: 10,
  /** @deprecated - starter is deprecated */
  [CustomerTypes.STARTER]: 2,
  /** @deprecated - standard is deprecated */
  [CustomerTypes.STANDARD]: 5,
};

const DEFAULT_MAX_CUSTOM_FONTS_PER_CUSTOMER_TYPE = {
  [CustomerTypes.LITE]: 1,
  [CustomerTypes.PRO]: 5,
  [CustomerTypes.ENTERPRISE]: 999,
  /** @deprecated - freemium is deprecated */
  [CustomerTypes.FREEMIUM]: 1,
  /** @deprecated - starter is deprecated */
  [CustomerTypes.STARTER]: 3,
  /** @deprecated - standard is deprecated */
  [CustomerTypes.STANDARD]: 5,
};

const DEFAULT_CUSTOMER_ADMIN_PER_CUSTOMER_TYPE = {
  [CustomerTypes.LITE]: true,
  [CustomerTypes.PRO]: true,
  [CustomerTypes.ENTERPRISE]: false,
  /** @deprecated - freemium is deprecated */
  [CustomerTypes.FREEMIUM]: true,
  /** @deprecated - starter is deprecated */
  [CustomerTypes.STARTER]: true,
  /** @deprecated - standard is deprecated */
  [CustomerTypes.STANDARD]: true,
};

interface ClientTableEntry {
  client: Client;
  deleted: boolean;
  new: boolean;
  updated: boolean;
}

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    AsyncPipe,
    DatePipe,
    NgSwitch,
    NgSwitchCase,
    NgForOf,

    MatIconAnchor,
    MatLabel,
    MatFormField,
    MatInput,
    MatTabGroup,
    MatTab,
    MatTabLabel,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatCellDef,
    MatHeaderCellDef,
    MatIconButton,
    MatCheckbox,
    MatSelect,
    MatOption,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatButton,

    ElementIdDirective,
    IconComponent,

    PremiumFeaturesFormComponent,
    FontCalcListComponent,
    FreeTrialFormComponent,
    UserListComponent,
    ErrorComponent,
  ],
})
export class AgencyComponent implements OnInit {
  @ViewChild(MatTable)
  clientTable!: MatTable<ClientTableEntry>;

  readonly createUrl = environment.create.link;

  public e2e: { [key in keyof typeof ElementId.Admin]: ElementIdType } =
    ElementId.Admin;

  customerTypeOptions: kit.CustomerType[] = [
    CustomerTypes.PRO,
    CustomerTypes.ENTERPRISE,
    CustomerTypes.LITE,
    /** @deprecated - freemium is deprecated */
    CustomerTypes.FREEMIUM,
  ];

  modes = Modes;

  agencyLink = '';

  hasUnsavedClientChanges = false;
  hasUnsavedPremiumChanges = false;
  hasUnsavedFreeTrialChanges = false;
  hasUnsavedFontChanges = false;
  hasUnsavedFormChanges = false;

  formLoading = false;

  hasExpiredTrial = false;

  usersDataSource = new UserListDataSource(this.cwsService);

  mode$ = this.route.data.pipe(map(({ mode }) => mode as Modes));

  clientFormFields = {
    hubspotCompanyId: 'hubspotCompanyId',
  };

  agencyId$ = this.route.params.pipe(
    map(({ agencyId }) => (agencyId ? String(agencyId) : undefined))
  );

  agency$ = this.agencyId$.pipe(
    switchMap((agencyId) =>
      agencyId ? this.agencyService.getAgency(agencyId) : of(undefined)
    ),
    shareReplay(1)
  );

  loading$ = this.agency$.pipe(
    map(() => false),
    startWith(true)
  );

  fonts$ = this.agencyId$.pipe(
    switchMap((agencyId) =>
      agencyId ? this.cwsService.listFonts(agencyId) : of(undefined)
    ),
    shareReplay(1)
  );

  agencyFeatureSettingsFormGroup = this.formBuilder.group({
    videoblocks: this.formBuilder.group({
      value: [
        {
          value: true,
          disabled: true,
        },
        Validators.required,
      ],
    }),
    getty: this.formBuilder.group({
      value: [
        {
          value: false,
          disabled: false,
        },
        Validators.required,
      ],
    }),
    premiumMusic: this.formBuilder.group({
      value: [
        {
          value: true,
          disabled: false,
        },
        Validators.required,
      ],
    }),
    scriptCreators: this.formBuilder.group({
      value: [
        {
          value: true,
          disabled: false,
        },
        Validators.required,
      ],
    }),
    maxUsersAmount: this.formBuilder.group({
      value: [
        {
          value: DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE[CustomerTypes.PRO],
          disabled: false,
        },
      ],
    }),
    maxCustomFont: this.formBuilder.group({
      value: DEFAULT_MAX_CUSTOM_FONTS_PER_CUSTOMER_TYPE[CustomerTypes.FREEMIUM],
      disabled: false,
    }),
    customerAdmin: this.formBuilder.group({
      value: DEFAULT_CUSTOMER_ADMIN_PER_CUSTOMER_TYPE[CustomerTypes.FREEMIUM],
      disabled: false,
    }),
    assetLibrary: this.formBuilder.group({
      value: [
        {
          value: true,
          disabled: true,
        },
        Validators.required,
      ],
    }),
  });
  agencyForm = this.formBuilder.group(
    {
      name: ['', Validators.required],
      hubspotCompanyId: ['', [Validators.required]],
      active: [true, Validators.required],
      customerType: [CustomerTypes.PRO, Validators.required],
      freeTrial: [false, Validators.required],
      freeTrialExpirationDate: [null],
      featureSettings: this.agencyFeatureSettingsFormGroup,
      settings: this.formBuilder.group({
        scriptCreatorLanguageInstruction: [''],
      }),
    },
    {
      validators: [this.freeTrialExpirationDateValidator],
    }
  );

  clientDataSource: ClientTableEntry[] = [];

  clientTableColumns = ['name', 'hubspotId', 'delete', 'link'];

  clientForm = this.formBuilder.group({
    name: ['', Validators.required],
  });

  clientDataForm = new FormRecord<FormControl<string>>({});

  /** @deprecated - freemium is deprecated */
  public isFreemiumAgency = false;

  public maxCustomFonts: number | undefined;

  clients$: Observable<Client[] | null>;

  public canEditLanguageInstruction$ =
    this.featureService.isFeatureEnabledSubject(
      FEATURE.CAN_EDIT_LANGUAGE_INSTRUCTION
    );

  private clientsSubject$ = new BehaviorSubject<Client[] | null>(null);

  private breadcrumbs$ = combineLatest([this.agency$, this.mode$]).pipe(
    map(([agency, mode]) => {
      const name = mode === Modes.Add ? 'New Agency' : agency?.name || '';
      return [{ label: 'Agencies', link: '/agencies' }, { label: name }];
    }),
    startWith([{ label: 'Agencies', link: '/agencies' }, { label: '' }])
  );

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private agencyService: AgencyService,
    private breadcrumbsService: BreadcrumbsService,
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    private dialog: MatDialog,
    private clientService: ClientService,
    private cdr: ChangeDetectorRef,
    private cache: ObservableCacheService,
    private cwsService: CwsService,
    private featureService: FeatureService,
    private destroyRef: DestroyRef
  ) {
    this.breadcrumbs$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((breadcrumbs) => {
        this.breadcrumbsService.set(breadcrumbs);
      });
    this.initCustomerTypeOptions();
    this.clients$ = this.clientsSubject$.asObservable();
  }

  ngOnInit(): void {
    combineLatest([this.agencyId$, this.clients$])
      .pipe(
        filter(([agencyId, clients]) => !!agencyId && !!clients),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([agencyId, clients]) => {
        this.agencyLink = `${this.createUrl}/${agencyId}`;

        this.usersDataSource.updateAccessIds([
          agencyId as string,
          ...(clients as Client[]).map((c) => c._id.toString()),
        ]);
      });

    this.agency$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((agency) => (agency ? this.setAgency(agency) : null));

    this.agencyForm
      .get('customerType')
      ?.valueChanges.pipe(
        withLatestFrom(this.agency$),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([customerType, agency]) => {
        this.setPremiumValues(customerType);

        const isDefaultAgencyUsersAmount =
          agency &&
          agency?.featureAccess?.maxUsersAmount?.value ===
            DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE[
              customerType as kit.CustomerType
            ];

        if (!agency || isDefaultAgencyUsersAmount) {
          this.agencyForm.controls.featureSettings
            .get('maxUsersAmount')
            ?.patchValue({
              value:
                DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE[
                  customerType as kit.CustomerType
                ],
            });
        }

        if (customerType === CustomerTypes.FREEMIUM) {
          this.agencyForm.controls.featureSettings
            .get('maxUsersAmount')
            ?.patchValue({
              value:
                DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE[CustomerTypes.FREEMIUM],
            });
          this.agencyForm.controls.featureSettings
            .get('scriptCreators')
            ?.patchValue({ value: true });
          this.agencyForm.controls.featureSettings
            .get('premiumMusic')
            ?.patchValue({ value: false });
          this.agencyForm.controls.featureSettings
            .get('videoblocks')
            ?.patchValue({ value: false });
          this.agencyForm.controls.featureSettings
            .get('getty')
            ?.patchValue({ value: false });

          this.isFreemiumAgency = true;
        } else {
          this.isFreemiumAgency = false;
        }

        this.agencyForm.controls.featureSettings
          .get('customerAdmin')
          ?.patchValue({
            value:
              DEFAULT_CUSTOMER_ADMIN_PER_CUSTOMER_TYPE[
                customerType as kit.CustomerType
              ],
          });

        if (!agency || customerType === CustomerTypes.FREEMIUM) {
          if (this.agencyForm.controls.featureSettings?.get('maxCustomFont')) {
            this.agencyForm.get('featureSettings.maxCustomFont')?.patchValue({
              value:
                this.maxCustomFonts ||
                agency?.featureSettings?.maxCustomFont?.value ||
                DEFAULT_MAX_CUSTOM_FONTS_PER_CUSTOMER_TYPE[
                  CustomerTypes.FREEMIUM
                ],
            });
          } else {
            (this.agencyForm.get('featureSettings') as FormGroup)?.addControl(
              'maxCustomFont',
              this.formBuilder.group({
                value:
                  DEFAULT_MAX_CUSTOM_FONTS_PER_CUSTOMER_TYPE[
                    CustomerTypes.FREEMIUM
                  ],
                disabled: false,
              })
            );
          }
        }
      });

    this.agencyId$
      .pipe(
        switchMap((agencyId) =>
          agencyId ? this.clientService.getClients(agencyId) : of([])
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((clients) => {
        this.clientsSubject$.next(clients);

        // init clients data source
        if (clients && clients.length) {
          clients.forEach((client, i) => {
            this.clientDataSource.push({
              client,
              deleted: false,
              new: false,
              updated: false,
            });
            this.clientDataForm.addControl(
              this.clientFormFields.hubspotCompanyId + i,
              new FormControl(client.hubspotCompanyId, {
                validators: [Validators.required],
                nonNullable: true,
              })
            );
          });

          this.clientTable?.renderRows();
        }

        this.cdr.detectChanges();

        this.agencyForm.valueChanges
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.hasUnsavedFormChanges = true;
          });
        this.agencyForm
          .get('featureSettings')
          ?.get('videoblocks')
          ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.hasUnsavedPremiumChanges = true;
          });
        this.agencyForm
          .get('featureSettings')
          ?.get('getty')
          ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.hasUnsavedPremiumChanges = true;
          });
        this.agencyForm
          .get('featureSettings')
          ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.hasUnsavedPremiumChanges = true;
          });
      });
  }

  setAgency(agency: Agency): void {
    if (agency?.customerType === CustomerTypes.FREEMIUM) {
      this.isFreemiumAgency = true;
      this.maxCustomFonts = agency?.featureAccess?.maxCustomFont?.value;
      this.patchPremiumValues('premiumMusic', false, false);
    }

    this.setPremiumValues(agency.customerType);
    this.updateAgencyFormFields(agency);

    const freeTrialExpirationDate = agency.freeTrialExpirationDate;
    const hasFreeTrialExpirationDate = !!freeTrialExpirationDate;
    this.agencyForm.controls.freeTrial.setValue(hasFreeTrialExpirationDate);
    if (hasFreeTrialExpirationDate) {
      this.agencyForm.controls.freeTrialExpirationDate.setValue(
        freeTrialExpirationDate
      );
      if (new Date(freeTrialExpirationDate) < new Date() && !agency.active) {
        this.hasExpiredTrial = true;
      }
      if (this.hasExpiredTrial) {
        this.agencyForm.disable();
      }
    }
  }

  initCustomerTypeOptions() {
    this.mode$
      .pipe(first())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((mode) => {
        if (mode === Modes.Edit) {
          if (!this.customerTypeOptions.includes(CustomerTypes.STANDARD)) {
            this.customerTypeOptions.push(CustomerTypes.STANDARD);
          }
          if (!this.customerTypeOptions.includes(CustomerTypes.STARTER)) {
            this.customerTypeOptions.push(CustomerTypes.STARTER);
          }
        }
      });
  }

  fontChangesHandler(fonts: boolean) {
    this.hasUnsavedFontChanges = fonts;
  }

  updateMaxCustomFonts(amount: number) {
    if (this.maxCustomFonts !== undefined) {
      const newAmount = this.maxCustomFonts + amount;
      this.agencyForm
        .get('featureSettings')
        ?.get('maxCustomFont')
        ?.patchValue({ value: newAmount, disabled: false });
      this.maxCustomFonts = newAmount;
    }
  }

  loadClients(): void {
    this.agencyId$
      .pipe(
        take(1),
        switchMap((agencyId) => {
          if (!agencyId) {
            return of([]);
          }
          return this.clientService.getClients(agencyId);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((clients) => {
        this.clientsSubject$.next(clients);
      });
  }

  removeFontLimitIfNotFreemium(): void {
    if (!this.isFreemiumAgency) {
      (this.agencyForm.get('featureSettings') as FormGroup).removeControl(
        'maxCustomFont'
      );
    }
  }

  async createAgency(): Promise<void> {
    try {
      this.formLoading = true;
      this.removeFontLimitIfNotFreemium();
      const agency = await lastValueFrom(
        this.agencyService.createAgency(this.agencyForm.value)
      );
      this.maxCustomFonts =
        this.agencyForm.value.featureSettings?.maxCustomFont?.value;

      this.notification.show('success', AgencyNotification.Created);
      this.cache.removeCacheEntry('clientsAgencies');
      void this.router.navigate(['/agencies', agency._id]);
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotCreated);
    }
    this.formLoading = false;
  }

  async updateAgency(): Promise<void> {
    const agencyId = (await firstValueFrom(this.agencyId$)) as string;
    this.formLoading = true;
    try {
      this.removeFontLimitIfNotFreemium();
      const agency = { ...this.agencyForm.value, _id: agencyId };
      await lastValueFrom(this.agencyService.updateAgency(agency));

      const clientCreateRequests = await this.createClients(agencyId);
      const clientUpdateRequests = await this.updateClients();
      const deleteClientsConfirmed = await this.deleteClients();

      if (clientCreateRequests.length || clientUpdateRequests.length) {
        this.cache.removeCacheEntry('clientsAgencies');
      }

      this.clientTable?.renderRows();

      this.fonts$ = this.cwsService.listFonts(agencyId);
      this.maxCustomFonts =
        this.agencyForm.value.featureSettings?.maxCustomFont?.value;

      this.updateAgencyFormFields(agency);
      this.resetFlags();

      this.notification.show(
        'success',
        `${AgencyNotification.Updated} ${deleteClientsConfirmed ? '' : `, ${AgencyNotification.ClientsNotDeleted}`}`
      );
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotUpdated);
    }

    this.loadClients();

    this.formLoading = false;
  }

  private async deleteClients(): Promise<boolean> {
    const deleteClientIds: string[] = this.clientDataSource
      .filter((clientTableEntry) => clientTableEntry.deleted)
      .map((clientTableEntry) => clientTableEntry.client._id);

    if (!deleteClientIds.length) {
      return true;
    }

    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'clients',
      amount: deleteClientIds.length,
    });

    const deleteClientsConfirmed = await lastValueFrom(dialogRef.afterClosed());
    if (!deleteClientsConfirmed) {
      this.clientDataSource = this.clientDataSource.map((value) => {
        value.deleted = false;
        return value;
      });
      return false;
    }

    for (const clientId of deleteClientIds) {
      await lastValueFrom(this.clientService.deleteClient(clientId));
    }
    this.clientDataSource = this.clientDataSource.filter(
      (clientTableEntry) => !clientTableEntry.deleted
    );
    this.cache.removeCacheEntry('clientsAgencies');
    return true;
  }

  private async createClients(agencyId: string) {
    const clientCreateRequests: Promise<Client>[] = this.clientDataSource
      .filter((clientTableEntry) => clientTableEntry.new)
      .map((clientTableEntry) =>
        lastValueFrom(
          this.clientService.createClient(clientTableEntry.client, agencyId)
        )
      );
    await Promise.all(clientCreateRequests).then((clients) => {
      clients.forEach((client) => {
        this.clientDataSource.push({
          client,
          deleted: false,
          new: false,
          updated: false,
        });
      });
    });

    this.clientDataSource = this.clientDataSource.filter(
      (clientTableEntry) => !clientTableEntry.new
    );
    return clientCreateRequests;
  }

  private async updateClients() {
    const clientUpdateRequests: Promise<Client>[] = [];
    this.clientDataSource
      .filter((clientTableEntry) => clientTableEntry.updated)
      .forEach((clientTableEntry) => {
        clientUpdateRequests.push(
          lastValueFrom(
            this.clientService.updateClient(
              clientTableEntry.client._id,
              clientTableEntry.client
            )
          )
        );
      });
    await Promise.all(clientUpdateRequests);
    return clientUpdateRequests;
  }

  resetFlags(): void {
    this.hasUnsavedClientChanges = false;
    this.hasUnsavedPremiumChanges = false;
    this.hasUnsavedFontChanges = false;
    this.hasUnsavedFormChanges = false;
  }

  async openDeleteDialog(): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'agency',
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      await this.deleteAgency();
    }
  }

  async openRestartTrialDialog(): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Reactivate',
      subject: 'agency',
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      await this.restartTrial();
    }
  }

  // Reset client input field error if left without a value
  clientInputBlur(): void {
    if (!this.clientForm.get('name')?.value) {
      this.clientForm.markAsUntouched();
    }
  }

  saveClient(
    clientTableEntry: ClientTableEntry,
    formName: string,
    index: number
  ) {
    const itemToUpdate = this.clientDataSource[index];
    itemToUpdate.client.hubspotCompanyId =
      this.clientDataForm.get(formName)?.value;
    itemToUpdate.updated = true;
    this.hasUnsavedClientChanges = true;
  }

  addClient() {
    const defaultHubspotValue = this.agencyForm.get(
      this.clientFormFields.hubspotCompanyId
    )?.value;
    this.clientDataForm.addControl(
      this.clientFormFields.hubspotCompanyId + this.clientDataSource.length,
      new FormControl(defaultHubspotValue, Validators.required)
    );
    this.clientDataSource.push({
      client: {
        name: this.clientForm.value.name,
        hubspotCompanyId: defaultHubspotValue,
      } as Client,
      deleted: false,
      new: true,
      updated: false,
    });
    this.clientForm.reset();
    this.clientTable?.renderRows();
    this.hasUnsavedClientChanges = true;
  }

  async deleteClient(
    clientTableEntry: ClientTableEntry,
    index: number
  ): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'client',
      name: clientTableEntry.client.name,
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (!confirmed) return;

    // Remove yet to be created clients from the data source
    if (clientTableEntry.new) {
      this.clientDataSource.splice(index, 1);
      this.clientTable?.renderRows();
      // true, if a new client has been created or an existing client has been updated/deleted
      this.hasUnsavedClientChanges = this.clientDataSource.some(
        (client) => client.new || client.updated || client.deleted
      );
      return;
    }

    // Show unsaved changes if client already in the data source is deleted before updating agency
    clientTableEntry.deleted = true;
    this.hasUnsavedClientChanges = true;
  }

  generateClientLinkCreate(clientTableEntry: ClientTableEntry) {
    const clientId = clientTableEntry.client._id;
    return `${this.createUrl}/clients/${clientId}`;
  }

  private async deleteAgency(): Promise<void> {
    const agencyId = (await firstValueFrom(this.agencyId$)) as string;

    try {
      await lastValueFrom(this.agencyService.deleteAgency(agencyId));
      this.notification.show('success', AgencyNotification.Deleted);
      this.router.navigate(['/agencies']);
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotDeleted);
    }
  }

  private async restartTrial(): Promise<void> {
    const agency = (await firstValueFrom(this.agency$)) as Agency;

    try {
      const restartTrialResponse = await lastValueFrom(
        this.cwsService.restartTrial(agency._id)
      );

      this.notification.show('success', AgencyNotification.RestartedTrial);
      if (restartTrialResponse) {
        const updatedAgency = {
          ...agency,
          freeTrialExpirationDate: restartTrialResponse.freeTrialExpirationDate,
        };
        this.agencyForm.enable();
        this.hasExpiredTrial = false;
        this.setAgency(updatedAgency);
        this.resetFlags();
      }
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotRestartedTrial);
    }
  }

  private updateAgencyFormFields(agency: Agency) {
    this.agencyForm.patchValue(agency);
    this.agencyForm.controls.featureSettings.patchValue(agency.featureAccess);
  }

  private freeTrialExpirationDateValidator(
    formGroup: FormGroup<FreeTrialForm>
  ): ValidationErrors | null {
    if (formGroup.value.freeTrial) {
      return Validators.required(
        formGroup.get('freeTrialExpirationDate') as AbstractControl
      );
    }

    return null;
  }

  private setPremiumValues(customerType: string) {
    switch (customerType) {
      case CustomerTypes.LITE:
        this.patchPremiumValues('premiumMusic', false, true);
        this.patchPremiumValues('videoblocks', false, true);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, false);
        this.patchPremiumValues('assetLibrary', false, true);
        break;
      case CustomerTypes.PRO:
        this.patchPremiumValues('premiumMusic', true, true);
        this.patchPremiumValues('videoblocks', true, false);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, true);
        this.patchPremiumValues('assetLibrary', true, false);
        break;
      case CustomerTypes.ENTERPRISE:
        this.patchPremiumValues('premiumMusic', true, true);
        this.patchPremiumValues('videoblocks', true, false);
        this.patchPremiumValues('getty', true, true);
        this.patchPremiumValues('scriptCreators', true, true);
        this.patchPremiumValues('assetLibrary', true, false);
        break;
      /** @deprecated - freemium is deprecated */
      case CustomerTypes.FREEMIUM:
        this.patchPremiumValues('premiumMusic', false, false);
        this.patchPremiumValues('videoblocks', false, false);
        this.patchPremiumValues('getty', false, false);
        this.patchPremiumValues('scriptCreators', true, true);
        this.patchPremiumValues('assetLibrary', false, false);
        break;
      /** @deprecated - starter is deprecated */
      case CustomerTypes.STARTER:
        this.patchPremiumValues('premiumMusic', false, true);
        this.patchPremiumValues('videoblocks', false, true);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, true);
        this.patchPremiumValues('assetLibrary', false, false);
        break;
      /** @deprecated - standard is deprecated */
      case CustomerTypes.STANDARD:
        this.patchPremiumValues('premiumMusic', false, true);
        this.patchPremiumValues('videoblocks', true, false);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, true);
        this.patchPremiumValues('assetLibrary', false, false);
        break;
      default:
        break;
    }
  }

  private patchPremiumValues(
    formField: string,
    enabledFeature: boolean,
    enabledForm: boolean
  ) {
    this.agencyForm.controls.featureSettings
      .get(formField)
      ?.patchValue({ value: enabledFeature });
    if (enabledForm) {
      this.agencyForm.controls.featureSettings.get(formField)?.enable();
    } else {
      this.agencyForm.controls.featureSettings.get(formField)?.disable();
    }
  }
}
