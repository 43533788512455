export const environment = {
  production: false,
  environment: 'stage',
  authProvider: 'auth0' as const,
  auth0: {
    domain: 'auth.storykit-stage.io',
    clientId: 'McKVwrlrOoNt6uTzFj9IQTZZpE9m80iD',
    logoutParams: { returnTo: 'https://admin.storykit-stage.io' },
    authorizationParams: {
      audience: 'storykit-stage-api',
      redirectUri: 'https://admin.storykit-stage.io/redirect',
    },
    listUsersUrl: 'https://cws.storykit-stage.io/admin/users',
    cookieDomain: '.storykit-stage.io',
  },
  api: {
    cws: {
      endpoint: 'https://cws.storykit-stage.io',
    },
    fileUploadUrl: {
      endpoint: 'https://fup.storykit-stage.io',
    },
  },
  apm: {
    serverUrl:
      'https://f0235b11a20a4a9bbf1cfc7ea57e5814.apm.eu-west-1.aws.cloud.es.io:443',
    environment: 'stage',
  },
  postHog: {
    token: 'phc_quALrhZBWsWSCxJXHywFtri9H2lK2yz17Wxj3EU1AnY',
    host: 'https://ph.storykit.io',
  },
  features: {
    admin_vsdp: false,
    can_edit_language_instruction: true,
  },
  create: {
    link: 'https://app.storykit-stage.io',
  },
};
